<template>
  <CreateUpdateTemplate customClass="role-create create-form">
    <template v-slot:header-title>
      <h1 class="form-title d-flex">
        <template v-if="role_id > 0">Update Role</template
        ><template v-else>Create a new Role</template>
      </h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="
          $router.push(
            getDefaultRoute('profile.setting', {
              query: {
                tab: 'role-permission',
              },
            })
          )
        "
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="onSubmit"
        color="cyan"
      >
        Save Role
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="roleForm"
        v-model="formValid"
        lazy-validation
        v-on:submit.stop.prevent="onSubmit"
      >
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 70vh; position: relative"
        >
          <v-container fluid>
            <v-row>
              <v-col md="4" class="py-0">
                <v-container fluid class="py-0">
                  <v-text-field
                    dense
                    filled
                    label="Role Name"
                    solo
                    flat
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                    v-model="updateRole.role_name"
                    :rules="[
                      validateRules.required(updateRole.role_name, 'Role Name'),
                      validateRules.minLength(
                        updateRole.role_name,
                        'Role Name',
                        2
                      ),
                      validateRules.maxLength(
                        updateRole.role_name,
                        'Role Name',
                        100
                      ),
                    ]"
                  ></v-text-field>
                </v-container>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="4" class="py-0">
                <v-container fluid class="py-0">
                  <v-textarea
                    v-model="updateRole.description"
                    auto-grow
                    dense
                    filled
                    color="cyan"
                    label="Description"
                    solo
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    flat
                    :rules="[
                      validateRules.minLength(
                        updateRole.description,
                        'Description',
                        1
                      ),
                      validateRules.maxLength(
                        updateRole.description,
                        'Description',
                        1024
                      ),
                    ]"
                    row-height="15"
                  ></v-textarea>
                </v-container>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="10" class="py-0">
                <v-container fluid class="py-0">
                  <v-card flat class="custom-grey-border remove-border-radius">
                    <v-card-title class="headline grey lighten-4 py-2">
                      <h3
                        class="font-weight-700 custom-headline color-custom-blue"
                        style="margin: 3.5px 0px"
                      >
                        Permissions
                      </h3>
                    </v-card-title>
                    <v-card-text class="p-6">
                      <template v-if="permissionLoading">
                        <span class="py-8 d-block font-size-16 font-weight-600"
                          >Getting permissions</span
                        >
                        <v-progress-linear
                          color="cyan"
                          indeterminate
                          rounded
                          height="6"
                        ></v-progress-linear>
                      </template>
                      <template v-else>
                        <table
                          v-if="lodash.isEmpty(permissions) === false"
                          class="width-100 font-size-14"
                        >
                          <tr>
                            <td class="custom-border-right pl-4"></td>
                            <td
                              width="100"
                              class="custom-border-right pl-4 py-2"
                            >
                              Full Access
                            </td>
                            <td
                              width="100"
                              class="custom-border-right pl-4 py-2"
                            >
                              View
                            </td>
                            <td
                              width="100"
                              class="custom-border-right pl-4 py-2"
                            >
                              Create
                            </td>
                            <td
                              width="100"
                              class="custom-border-right pl-4 py-2"
                            >
                              Edit
                            </td>
                            <td
                              width="100"
                              class="custom-border-right pl-4 py-2"
                            >
                              Delete
                            </td>
                            <td width="100" class="pl-4">Approve</td>
                          </tr>
                          <tr v-for="(row, index) in permissions" :key="index">
                            <td
                              class="custom-border-right custom-border-top py-3"
                            >
                              {{ lodash.upperFirst(lodash.lowerCase(index)) }}
                            </td>
                            <template v-for="(module, ind) in row">
                              <td
                                :key="index + ind"
                                width="100"
                                class="custom-border-top custom-border-right pl-4"
                              >
                                <v-checkbox
                                  multiple
                                  :disabled="pageLoading"
                                  v-model="formPermissions"
                                  v-on:change="changeFullAccess(module, index)"
                                  color="cyan"
                                  class="my-0"
                                  dense
                                  :value="module.id"
                                  hide-details
                                ></v-checkbox>
                              </td>
                            </template>
                            <td
                              v-if="row.length != 6"
                              :colspan="6 - row.length"
                              class="custom-border-top pl-4 py-1"
                            ></td>
                          </tr>
                        </table>

                        <template v-else>
                          <p
                            class="m-0 row-not-found text-center font-weight-500 font-size-16"
                          >
                            <img
                              width="40"
                              :src="$assetURL('media/error/empty.png')"
                              class="row-not-found-image mx-2"
                            />
                            Uhh... There are no permission at the moment.
                          </p>
                        </template>
                      </template>
                    </v-card-text>
                  </v-card>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </perfect-scrollbar>
      </v-form>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { POST, GET, PATCH } from "@/core/services/store/request.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";

export default {
  mixins: [ValidationMixin],
  name: "role-create",
  data() {
    return {
      role_id: 0,
      clone_id: 0,
      updateRole: {
        role_name: null,
        description: null,
      },
      formPermissions: [],
      permissions: [],
      pageLoading: false,
      permissionLoading: true,
    };
  },
  components: {
    CreateUpdateTemplate,
  },
  methods: {
    changeFullAccess(param, index) {
      const _this = this;
      try {
        if (param.name == "full-access") {
          let append = true;
          if (
            _this.lodash.includes(_this.formPermissions, param.id) === false
          ) {
            append = false;
          }

          for (let i = 0; i < _this.permissions[index].length; i++) {
            if (append) {
              if (
                _this.lodash.includes(
                  _this.formPermissions,
                  _this.permissions[index][i].id
                ) === false
              ) {
                _this.formPermissions.push(_this.permissions[index][i].id);
              }
            } else {
              let removeIndex = _this.lodash.findIndex(
                _this.formPermissions,
                function (row) {
                  return row == _this.permissions[index][i].id;
                }
              );
              if (removeIndex >= 0) {
                _this.formPermissions.splice(removeIndex, 1);
              }
            }
          }
        } else {
          if (
            _this.lodash.includes(_this.formPermissions, param.id) === false
          ) {
            for (let i = 0; i < _this.permissions[index].length; i++) {
              if (_this.permissions[index][i].name == "full-access") {
                let removeIndex = _this.lodash.findIndex(
                  _this.formPermissions,
                  function (row) {
                    return row == _this.permissions[index][i].id;
                  }
                );
                if (removeIndex >= 0) {
                  _this.formPermissions.splice(removeIndex, 1);
                }
              }
            }
          } else {
            let currentIndexValues = [];
            for (let i = 0; i < _this.permissions[index].length; i++) {
              if (
                _this.lodash.includes(
                  _this.formPermissions,
                  _this.permissions[index][i].id
                )
              ) {
                currentIndexValues.push(_this.permissions[index][i].id);
              }
            }
            if (
              _this.permissions[index].length ===
              currentIndexValues.length + 1
            ) {
              for (let i = 0; i < _this.permissions[index].length; i++) {
                if (
                  _this.lodash.includes(
                    _this.formPermissions,
                    _this.permissions[index][i].id
                  ) === false
                ) {
                  _this.formPermissions.push(_this.permissions[index][i].id);
                }
              }
            }
          }
        }
      } catch (error) {
        ErrorEventBus.$emit("update:error", InitializeError(error));
      }
    },
    checkAllPermissions() {
      const _this = this;
      let result = [];
      for (let index in _this.permissions) {
        for (let i = 0; i < _this.permissions[index].length; i++) {
          if (
            _this.lodash.includes(result, _this.permissions[index][i].id) ===
            false
          ) {
            result.push(
              _this.lodash.toSafeInteger(_this.permissions[index][i].id)
            );
          }
        }
      }
      _this.formPermissions = _this.lodash.cloneDeep(result);
    },
    updatePermissions(permissionData) {
      const _this = this;
      let result = [];
      for (let index in _this.permissions) {
        for (let i = 0; i < _this.permissions[index].length; i++) {
          if (
            _this.lodash.includes(
              permissionData,
              _this.permissions[index][i].id
            )
          ) {
            result.push(
              _this.lodash.toSafeInteger(_this.permissions[index][i].id)
            );
          }
        }
      }
      _this.formPermissions = _this.lodash.cloneDeep(result);
    },
    createRole() {
      const _this = this;
      return new Promise((resolve, reject) => {
        _this.$store
          .dispatch(POST, {
            url: "role",
            data: {
              text: _this.updateRole.role_name,
              description: _this.updateRole.description,
            },
          })
          .then(({ data }) => {
            resolve(data.id);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    assignPermissions() {
      const _this = this;
      return new Promise((resolve, reject) => {
        _this.$store
          .dispatch(PATCH, {
            url: "role/" + _this.role_id + "/permission",
            data: {
              permission: _this.formPermissions,
            },
          })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async onSubmit() {
      const _this = this;
      try {
        if (!_this.$refs.roleForm.validate()) {
          return false;
        }

        _this.formLoading = true;

        if (!_this.role_id) {
          _this.role_id = await _this.createRole();
        }

        await _this.assignPermissions();

        _this.$router.push(
          _this.getDefaultRoute("profile.setting", {
            query: {
              tab: "role-permission",
            },
          })
        );

        _this.formLoading = false;
      } catch (error) {
        _this.formLoading = false;
      }
    },
    getPermissions() {
      const _this = this;
      return new Promise((resolve, reject) => {
        _this.$store
          .dispatch(GET, { url: "permission" })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    loadRole(role_id) {
      const _this = this;
      return new Promise((resolve, reject) => {
        _this.$store
          .dispatch(GET, {
            url: "role/" + role_id,
          })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    loadContent(role_id) {
      const _this = this;
      _this
        .loadRole(role_id)
        .then(({ name, description, permissions }) => {
          _this.updateRole = {
            role_name: name,
            description: description,
          };
          if (permissions && permissions.length > 0) {
            let result = permissions.map((row) => {
              return _this.lodash.toSafeInteger(row.permission_id);
            });
            _this.updatePermissions(result);
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    _this
      .getPermissions()
      .then((response) => {
        _this.permissions = response || [];
        if (_this.$route.params.id) {
          _this.pageLoading = true;
          _this.role_id = _this.lodash.toSafeInteger(_this.$route.params.id);
          _this.loadContent(_this.role_id);
        }

        if (_this.$route.query.clone) {
          _this.pageLoading = true;
          _this.clone_id = _this.lodash.toSafeInteger(_this.$route.query.clone);
          _this.loadContent(_this.clone_id);
        }
      })
      .finally(() => {
        _this.permissionLoading = false;
        if (!_this.role_id && !_this.clone_id) {
          _this.checkAllPermissions();
        }
      });
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Role", route: "role" },
      { title: "Create" },
    ]);
  },
};
</script>
